<template>
    <div>
      <section class="account-details px-0 py-0">
        <div class="card p-1 s-a-1" style="border: solid gray 1px;background-color: var(--lightest-gray);">
          <div class="row px-1 py-1 m-0 mb-2">
            <div class="col-6">
              <div class="line-height-0" v-if="profile.new_profile === false">
                <span style="color: black;" class="player-profile-details s-a-1-txt">Player profile details:</span><br />
                <strong class="text-dark">{{ myProfile.first_name }} {{ myProfile.last_name }}</strong>
              </div>
              <div class="line-height-0" v-else>
                <span style="color: black;" class="player-profile-details s-a-1-txt">Karibu Ponyoka!</span>
              </div>
            </div>
            <div class="col-6">
              <div class="account">
                <p>
                  <span class="text-dark">Bonus available:
                    <strong> {{ myBonus }} </strong></span><br />
                  <!-- <span class="text-dark">
                    MB8 Tokens:
                    <strong>{{ receivedMB8Balance }}</strong></span> -->
                </p>
                <!-- <span class="s-a-1-txt">Bonus</span><br /> -->
                <!-- <div class="accordion">
                  <details>
                    <summary style="background-color: var(--yellow);color: var(--grey);">View All Bonuses:</summary>
                    <p>
                      <span class="text-dark">Bonus available:
                        <strong> {{ myBonus }} </strong></span><br />
                      <span class="text-dark">
                        MB8 Tokens:
                        <strong>{{ receivedMB8Balance }}</strong></span>
                    </p>
                  </details>
                </div> -->
              </div>
            </div>
          </div>
          <div class="row px-1 py-1 m-0">
            <div class="col-6">
              <div class="line-height-0">
                <span style="color: black;" class="player-profile-details s-a-1-txt">Account Number</span><br />
                <strong class="text-dark">{{ myProfile.msisdn }}</strong><br />
              </div>
            </div>
            <div class="col-6">
              <div class="account">
                <span style="color: black;" class="s-a-1-txt">Account Balance</span><br />
                <strong class="text-dark">Ksh. <span>{{ myBalance }}</span></strong>
              </div>
            </div>
          </div>
        </div>
      </section>
  <!-- 
      <hr class="m-1" />
      <div class="card p-1 s-a-1" style="border: solid gray 1px;background-color: var(--lightest-gray);color: black;">
        <div class="text-dark text-center matches-title mb-1">
          <h4 style="border-bottom: 1px solid black;color: black;">Deposit</h4>
          <span style="color: black;" class="s-a-1-txt">Top up your Ponyoka Account</span>
        </div>
        <section class="px-2 mb-3">
          <div class="form-wrapper1 s-a-1 mb-0">
            <div class="d-flex justify-content-between mb-2">
              <div class="depo-btn-wrapper">
                <button style="background-color: var(--yellow);color: var(--grey);" class="btn odd-btn px-2"
                  @click="setAmount(50)">
                  +<span class="deposit_val">50</span>
                </button>
              </div>
              <div class="depo-btn-wrapper">
                <button style="background-color: var(--yellow);color: var(--grey);" class="btn odd-btn px-2"
                  @click="setAmount(100)">
                  +<span class="deposit_val">100</span>
                </button>
              </div>
              <div class="depo-btn-wrapper">
                <button style="background-color: var(--yellow);color: var(--grey);" class="btn odd-btn px-2"
                  @click="setAmount(200)">
                  +<span class="deposit_val">200</span>
                </button>
              </div>
              <div class="depo-btn-wrapper">
                <button style="background-color: var(--yellow);color: var(--grey);" class="btn odd-btn px-2"
                  @click="setAmount(500)">
                  +<span class="deposit_val">500</span>
                </button>
              </div>
              <div class="depo-btn-wrapper">
                <button style="background-color: var(--yellow);color: var(--grey);" class="btn odd-btn px-2"
                  @click="setAmount(1000)">
                  +<span class="deposit_val">1000</span>
                </button>
              </div>
            </div>
  
            <div class="mb-0">
              <input style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 30px;text-align: center;"
                type="number" name="amount" id="deposit" placeholder="10" min="0" class="" value="99"
                aria-label="Amount (to the nearest dollar)" v-model="amount" />
            </div>
            <small style="color: red;" class="s-a-1-txt mt-3 mb-3">Minimum Deposit amount: Ksh 1</small>
  
            <button style="background-color: var(--yellow);color: var(--grey);" @click="deposit" id="deposit-profile"
              v-bind:class="loading" class="copy-betslip-btn1 py-2 form-control">
              Deposit
            </button>
          </div>
        </section>
      </div> -->
  
      <hr class="m-1" />
  
      <!-- <div class="card p-1 s-a-1" style="border: solid gray 1px;background-color: var(--lightest-gray);color: black;">
        <div class="direct-deposit s-a-1 text-center s-a-1-txt mb-2">
          <h4 class="s-a-2-text" style="border-bottom: 1px solid var(--grey);color: black;">
            Deposit Direct Via Paybill
          </h4>
        </div>
        <div class="deposit-details d-flex justify-content-between px-3">
          <div class="s-a-1-txt">
            <span style="color: black;">Paybill Number: </span> <span class="paybill text-dark">222563</span>
          </div>
          <div class="s-a-1-txt">
            <span style="color: black;">Account Number: </span>
            <span class="paybill text-dark" style="font-style: italic; font-weight: 600">{{ myProfile.msisdn }}</span>
          </div>
        </div>
      </div>
  
      <hr class="m-1" /> -->
      <div class="card p-1 s-a-1" style="border: solid gray 1px;background-color: var(--lightest-gray);color: black;">
        <div class="text-dark text-center matches-title mb-1 pt-2">
          <h4 style="
              color: black;
              border-bottom: 1px solid var(--secondary);
            ">
            Withdraw
          </h4>
          <!-- <p style="margin-bottom:0px;font-size: 15px; border-bottom: 1px solid var(--primary);">Withdraw</p> -->
          <span style="color: black;" class="s-a-1-txt">Withdraw funds from your Ponyoka account</span>
        </div>
        <section class="px-2 mb-3">
          <div class="form-wrapper s-a-1 mb-0">
            <div class="mb-0">
              <input style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 30px;text-align: center;"
                type="number" class="form-control" name="amount" placeholder="100" min="0" aria-label="Amount"
                v-model="withdraw_amount" />
            </div>
            <small style="color: red;" class="s-a-1-txt mb-3 mt-3">Minimum Ksh. 100 Maximum Ksh. 70,000</small>
            <button style="background-color: var(--yellow);color: var(--grey);" @click="withdraw" v-bind:class="loading"
              class="copy-betslip-btn1 py-2 form-control" :disabled="loading === 'loading'">
              {{ loading === 'loading' ? 'Processing...' : 'Withdraw' }}
            </button>
          </div>
        </section>
      </div>
      <hr class="m-1"/>
      <div class="card p-1 s-a-1" style="border: solid gray 1px;background-color: var(--lightest-gray);color: black;">
        <div class="text-dark text-center matches-title mb-1 pt-2">
          <h4 style="
              color: black;
              border-bottom: 1px solid var(--secondary);
            ">
            Deposit
          </h4>
          <!-- <p style="margin-bottom:0px;font-size: 15px; border-bottom: 1px solid var(--primary);">Withdraw</p> -->
          <span style="color: black;" class="s-a-1-txt">Top up your account</span>
        </div>
        <section class="px-2 mb-3">
          <div class="form-wrapper s-a-1 mb-0">
            <div class="mb-0">
              <input style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 30px;text-align: center;"
                type="number" class="form-control" name="amount" placeholder="1" min="0" aria-label="Amount"
                v-model="amount" />
            </div>
            <small style="color: red;" class="s-a-1-txt mb-3 mt-3">Minimum Deposit amount: Ksh. 1</small>
            <button style="background-color: var(--yellow);color: var(--grey);" @click="deposit" v-bind:class="loading"
              class="copy-betslip-btn1 py-2 form-control" :disabled="loading === 'loading'">
              {{ loading === 'loading' ? 'Processing...' : 'Deposit' }}
            </button>
          </div>
        </section>
      </div>
  
      <hr class="m-1" />
  
      <!--    Bonus Redeemer-->
      <div class="card p-1 s-a-1" style="border: solid gray 1px;background-color: var(--lightest-gray);color: black;">
        <div class="text-dark text-center matches-title mb-1 pt-2">
          <h4 style="
              color: black;
              border-bottom: 1px solid var(--secondary);
            ">
            Redeem Bonus
          </h4>
          <!-- <p style="margin-bottom:0px;font-size: 15px; border-bottom: 1px solid var(--primary);">Withdraw</p> -->
          <span style="color: black;" class="s-a-1-txt">Enter Code to Redeem Bonus</span>
        </div>
        <section class="px-2 mb-3">
          <div class="form-wrapper s-a-1 mb-0">
            <div class="mb-0">
              <input style="border: 1px black solid;border-radius: 5px;background-color: white;color: black;width: 100%;height: 30px;text-align: center;" type="text"
                class="form-control" name="bonusCode" placeholder="Enter Bonus Code" min="4" aria-label="BonusCode"
                v-model="bonus_code" />
            </div>
            <div class="mb-2"></div>
            <button style="background-color: var(--yellow);color: var(--grey);" @click="redeemBonus"
              v-bind:class="loading" class="copy-betslip-btn1 py-2 form-control">
              Redeem Bonus
            </button>
          </div>
        </section>
      </div>
  
      <hr class="m-1" />
  
      <span id="open-shareinvite" class="hidden"></span>
  
      <!-- The Modal -->
      <div class="sharebet-modal" id="shareinvite-modal"
        style="border: solid gray 1px;background-color: var(--lightest-gray);color: black;">
        <div class="sharebet-modal-content">
          <div class="row d-flex">
            <div class="col-12 share-bet-title text-center justify-content-center">
              <strong>Share Your Invite Code</strong><br />
              <span>Win Your Refferral Bonus</span>
            </div>
  
            <div class="col-12 share-bet-desc text-center" @click="copyCode">
              <div class="booking-code copy-betslip-btn1 py-2 form-control" style="color: green; width: auto;">
                <strong>{{ code }}</strong>
                {{ copyBookingCode }}
              </div>
            </div>
          </div>
  
          <div class="row d-flex">
            <div class="col-12">
              <div class="share-bet-link text-center" id="share-bet-link">
                {{ link }}
              </div>
  
              <div style="color: var(--primary); font-style: italic" class="hidden text-center pt-2 pb-2"
                id="share-invite-msg">
                {{ msg }}
              </div>
  
              <div style="color: var(--grey);text-align:center;"
                class="copy-betslip-btn1 copy-betslip-btn1 py-2 form-control" @click="copyShareBetLink">
                {{ copyText }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br/>
    </div>
  </template>
  
  
  <script>
  import walletserve from "@/services/walletserve";
  import bonusserve from "@/services/bonus";
  
  export default {
    name: "Setting",
    components: {},
    data: function () {
      return {
        amount: "",
        mb8Balance: "0",
        loading: "",
        pro: {},
        bonus_code: "",
        errorDisplayed: false,
        withdraw_amount: "",
        new_referral_code: "",
        errors: [],
        code: "",
        link: "",
        copyText: "Copy",
        copyBookingCode: "Click to copy",
        myProfile: this.getProfile(),
      };
    },
    watch: {
      $route: {
        immediate: true,
        handler() {
          // react to route changes...
          document.title = "My Ponyoka Account";
          document.description = "Manage Ponyoka account";
        },
      },
      new_referral_code: function (n) {
        console.log("got new value here " + n);
  
        this.validateReferralLink();
      },
    },
    created() {
      var profile = this.getProfile();
  
      // Check if the user is logged in
      var isLoggedIn = profile && profile.a;
  
      if (isLoggedIn) {
        this.EventBus.$on("mb8:updated", (mb8balance) => {
          this.receivedMB8Balance = mb8balance;
        });
        this.EventBus.$on("bonus:updated", (bonus) => {
          this.receivedBonus = bonus;
        });
      }
    },
    mounted() {
      this.$store.dispatch("setCurrentPage", "setting");
      var vm = this;
  
      vm.myProfile = vm.getProfile();
      // console.log("My Profile data", vm.myProfile);
      this.reloadProfile();
  
      this.getBonus();
  
      if (!vm.myProfile) {
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;
      }
  
      setInterval(function () {
        vm.myProfile = vm.getProfile();
      }, 1000);
  
      this.code = vm.myProfile.referral_code;
      // this.link = vm.myProfile.referral_link;
      this.initShareInviteModal();
    },
    methods: {
      setAmount: function (amountToAdd) {
        // Convert the current amount to a number
        let currentAmount = parseFloat(this.amount);
  
        // Check if the current amount is a valid number
        if (isNaN(currentAmount)) {
          currentAmount = 0; // Set it to 0 if it's not a valid number
        }
  
        // Add the new amount to the existing amount
        currentAmount += amountToAdd;
  
        // Update the amount with the new total
        this.amount = currentAmount.toFixed(2); // Limit to 2 decimal places
      },
  
      generateOrRegenerateReferralCode: function () {
        this.reset();
        this.errors = [];
  
        var p = this.getProfile();
        var ref = p.referral_code;
        if (!p) {
          this.setError("Login", "Please login to proceed");
          this.$router.push({ name: "login", params: {} });
          return;
        }
  
        return ref;
      },
  
      redeemBonus: function () {
        var vm = this;
        var path = process.env.VUE_APP_BASE_BONUS_URL + "/campaign/redeem";
  
        var payload = {
          bonus_code: String(this.bonus_code),
        };
  
        bonusserve
          .post(path, JSON.stringify(payload), {
            headers: {
              "api-key": vm.getAuth(),
            },
          })
          .then((res) => {
            vm.loading = "";
            console.log("Response for Bonus", res);
            // var bonusdata = res.data.data;
  
            vm.bonusdata = res.data.data; 7
          })
          .catch((err) => {
            console.log("Error", err);
            vm.loading = "";
            if (err.response) {
              // console.log(JSON.stringify(err.request));
            } else {
              vm.setError(
                "Network Error",
                "Check your network connection and try again"
              );
              // console.log(JSON.stringify(err));
            }
          });
      },
  
      deposit: function () {
        this.reset();
        var p = this.getAuth();
        if (!p) {
          this.setError("Login", "Please login to proceed");
          this.$router.push({ name: "login", params: {} });
          return;
        }
  
        if (this.amount < 1) {
          this.setError("Invalid Amount", "Enter amount atleast 1 KSH or above");
          return;
        }
  
        var vm = this;
        var path = process.env.VUE_APP_BASE_WALLET_URL + "/deposit/initiate";
  
        var utm_source = this.getValue("utm_source");
        var utm_medium = this.getValue("utm_medium");
        var utm_campaign = this.getValue("utm_campaign");
        var referrer = this.getValue("referrer");
  
        var data = {
          amount: parseInt(this.amount),
          utm_source: utm_source,
          utm_medium: utm_medium,
          utm_campaign: utm_campaign,
          referrer: referrer,
        };
  
        vm.loading = "loading";
  
        walletserve
          .post(path, JSON.stringify(data), {
            headers: {
              "api-key": vm.getAuth(),
            },
          })
          .then((res) => {
            vm.loading = "";
            var msg = res.data.message.data;
            vm.setSuccess("Deposit Initiated", msg);
          })
          .catch((err) => {
            vm.loading = "";
            if (err.response) {
              if (
                parseInt(err.response.data.error_code) === 401 ||
                parseInt(err.response.data.error_code) === 400 ||
                parseInt(err.response.data.error_code) === 428
              ) {
                vm.setError(
                  "Session Expired",
                  "Your session on this device has expired"
                );
                vm.logout();
                return;
              } else {
                vm.setError("Failed", err.response.data.error_message);
                // console.log(JSON.stringify(err.response.data.message));
              }
            } else if (err.request) {
              vm.setError(
                "Network Error",
                "Check your network connection and try again"
              );
              // console.log(JSON.stringify(err.request));
            } else {
              vm.setError(
                "Network Error",
                "Check your network connection and try again"
              );
              // console.log(JSON.stringify(err));
            }
          });
      },
  
      // createReferralLink: function () {
      //   this.reset();
      //   this.errors = [];
  
      //   var p = this.getProfile();
      //   if (!p) {
      //     this.setError("Login", "Please login to proceed");
      //     this.$router.push({ name: "login", params: {} });
      //     return;
      //   }
  
      //   if (this.new_referral_code.length < 4) {
      //     this.errors.push("Referral code should be more than 3 characters");
      //   }
  
      //   if (this.new_referral_code.length > 20) {
      //     this.errors.push("Referral code should be less than 21 characters");
      //   }
  
      //   if (!/^[0-9a-zA-Z]+$/.test(this.new_referral_code)) {
      //     this.errors.push(
      //       "Referral code can only contain letters A-Z and numbers 0 - 9"
      //     );
      //   }
  
      //   if (this.errors.length > 0) {
      //     return;
      //   }
  
      //   var vm = this;
      //   var path = process.env.VUE_APP_URL_GENERATE_REFERRAL_LINK.replace(
      //     "{profile_id}",
      //     p.d
      //   );
  
      //   var data = {
      //     code: this.new_referral_code,
      //   };
  
      //   vm.loading = "loading";
  
      //   axios
      //     .post(path, JSON.stringify(data), {
      //       headers: {
      //         "api-key": vm.getAuth(),
      //       },
      //     })
      //     .then((res) => {
      //       vm.loading = "";
      //       vm.link = res.data.message.profile.referral_link;
      //       vm.code = res.data.message.profile.referral_code;
      //       var prof = res.data.message.profile;
      //       var msg = res.data.message.description;
  
      //       if (prof) {
      //         vm.setProfile(prof);
      //       }
  
      //       vm.setSuccess("Link Generated", msg);
      //     })
      //     .catch((err) => {
      //       vm.loading = "";
  
      //       if (err.response) {
      //         if (
      //           parseInt(err.response.status) === 401 ||
      //           parseInt(err.response.status) === 400 ||
      //           parseInt(err.response.status) === 428
      //         ) {
      //           vm.setError(
      //             "Session Expired",
      //             "Your session on this device has expired"
      //           );
      //           vm.logout();
      //           return;
      //         } else {
      //           vm.setError("Failed", err.response.data.message);
      //           vm.errors.push(err.response.data.message);
      //         }
      //       } else if (err.request) {
      //         vm.setError(
      //           "Network Error",
      //           "Check your network connection and try again"
      //         );
      //         console.log(JSON.stringify(err.request));
      //       } else {
      //         vm.setError(
      //           "Network Error",
      //           "Check your network connection and try again"
      //         );
      //         console.log(JSON.stringify(err));
      //       }
      //     });
      // },
      // validateReferralLink: function () {
      //   this.errors = [];
  
      //   if (this.new_referral_code.length < 4) {
      //     this.errors.push("Referral code should be more than 3 characters");
      //   }
  
      //   if (this.new_referral_code.length > 20) {
      //     this.errors.push("Referral code should be less than 21 characters");
      //   }
  
      //   if (!/^[0-9a-zA-Z]+$/.test(this.new_referral_code)) {
      //     this.errors.push(
      //       "Referral code can only contain letters A-Z and numbers 0 - 9"
      //     );
      //   }
      // },
  
  
      withdraw: function () {
        if (this.loading === 'loading') {
          return;
        }
  
        this.loading = 'loading';
  
        var vm = this;
  
        setTimeout(function () {
          vm.loading = '';
        }, 5000);
  
        this.reset();
        var p = this.getAuth();
        if (!p) {
          this.setError("Login", "Please login to proceed");
          this.$router.push({ name: "login", params: {} });
          return;
        }
  
        if (this.withdraw_amount < 100) {
          this.setError(
            "Invalid Amount",
            "Enter amount at least 100 KSH or above"
          );
          return;
        }
  
        var path = process.env.VUE_APP_BASE_WALLET_URL + "/withdraw";
  
        var data = {
          amount: parseInt(this.withdraw_amount),
          // msisdn: parseInt(this.msisdn),
        };
  
        walletserve
          .post(path, data, {
            headers: {
              "api-key": vm.getAuth(),
            },
          })
          .then((res) => {
            console.log("Response for Withdraw", res)
            vm.loading = "";
            var msg = res.data.data;
            if (parseInt(res.data.status) === 200) {
              vm.setSuccess("Withdrawal Initiated", msg);
            }
            if (parseInt(res.data.status) === 201) {
              vm.setSuccess("Withdrawal Initiated", msg);
            }
          })
          .catch((err) => {
            console.log("Error", err)
            vm.loading = "";
            var msg = err.response.data.error_message;
            if (!vm.errorDisplayed) {
              if (parseInt(err.response)) {
                vm.setError("Failed", msg);
              } else if (
                parseInt(err.response.data.error_code) === 401 ||
                parseInt(err.response.data.error_code) === 400 ||
                parseInt(err.response.data.error_code) === 428
              ) {
                vm.setError(
                  "Session Expired",
                  "Your session on this device has expired"
                );
                vm.logout();
              } else {
                vm.setError("Failed", msg);
                // console.log(JSON.stringify(err.response.data.message));
              }
              vm.errorDisplayed = true;
            }
          });
      },
  
      shareInvite: function () {
        document.getElementById("open-shareinvite").click();
      },
      copyShareBetLink: function () {
        var profile = this.getProfile();
        if (!profile) {
          return;
        }
        var link = "https://Ponyoka.com/join?p=" + profile.referral_code;
        this.copyToClipboard(link);
        this.copyText = "Copied";
      },
  
      copyCode: function () {
        var link = "accept#" + this.code;
        this.copyToClipboard(link);
        this.copyBookingCode = "Invite Code Copied";
      },
  
      initShareInviteModal: function () {
        var modal = document.getElementById("shareinvite-modal");
  
        // Get the button that opens the modal
        var btn = document.getElementById("open-shareinvite");
  
        // Get the <span> element that closes the modal
        //var span = document.getElementsByClassName("sharebet-close")[0];
  
        // When the user clicks on the button, open the modal
        btn.onclick = function () {
          modal.style.display = "block";
        };
  
        // When the user clicks on <span> (x), close the modal
        /*
        span.onclick = function() {
          modal.style.display = "none";
        }
        */
  
        // When the user clicks anywhere outside of the modal, close it
        window.onclick = function (event) {
          if (event.target == modal) {
            modal.style.display = "none";
          }
        };
  
        document.addEventListener("click", (e) => {
          if (e.target == document.querySelector("#shareinvite-modal")) {
            modal.style.display = "none";
          }
        });
      },
    },
    computed: {
      bal: function () {
        return this.formatCurrency(this.profile.b1);
      },
      bonus: function () {
        return this.formatCurrency(
          this.profile.balance + this.profile.pending_activation
        );
      },
      myBalance: function () {
        return this.formatCurrency(this.$store.state.balance);
      },
      myBonus: function () {
        return this.$store.state.bonus;
      },
      msg: function () {
        return "Karibu Ponyoka, Come let's win BIG together.";
      },
      profile: function () {
        return this.myProfile;
      },
      // has_referral_code: function () {
      //   return this.code !== undefined && this.code.length > 3;
      // },
      shouldDisplayTrivia() {
        const now = new Date();
        const startDate = new Date("2023-11-22T12:00:00");
        const endDate = new Date("2023-11-22T15:30:00");
  
        return now >= startDate && now <= endDate;
      },
    },
    filters: {
      currency: function (amount) {
        if (amount == null) {
          amount = 0;
        }
  
        return amount.toLocaleString("en-US", {
          style: "currency",
          currency: "KES",
        });
      },
      formatOdds: function (x) {
        if (x === undefined) {
          return 1;
        }
  
        return parseFloat(x).toFixed(2);
      },
    },
  };
  </script>
  